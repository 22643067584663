<template>
<div>
      <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top navbar-top">
        <router-link :to="{ name: 'Start' }" class="navbar-brand mr-4">
          <img :src="baseUrl + '/lg.jpg'" width="107.5" height="43" />
        </router-link>
        <b-navbar-brand v-if="ready">{{ad.configuration.title}}</b-navbar-brand>
        <b-navbar-nav class="ml-auto" v-if="permissions.ut & ready">
          <b-nav-text right>
            <b-button v-b-toggle.sidebar-ut class="mr-2">
              <b-icon-three-dots-vertical aria-hidden="true"></b-icon-three-dots-vertical>
            </b-button>
          </b-nav-text>
        </b-navbar-nav>
      </nav>
  <div v-if="!permissions.ut" class="m-4 p-4 text-centre"><h1>No access permissions</h1></div>
  <div v-if="permissions.ut & ready" v-bind:class="[backgroundClass]">
    <router-view></router-view>
    <b-sidebar id="sidebar-ut" :title="ad.configuration.title" right shadow bg-variant="dark" text-variant="light">
      <div class="px-3 py-2">
        <b-nav vertical>
          <b-nav-item :to="{ name: 'AppUT' }"><b-icon-house-door aria-hidden="true"></b-icon-house-door> Start</b-nav-item>
          <b-nav-item :to="{ name: 'AppUTPresentation' }">Presentation</b-nav-item>
          <b-nav-item :to="{ name: 'AppUTControls' }">Controls</b-nav-item>
          <b-nav-item :to="{ name: 'AppUTTimeline' }">Timeline</b-nav-item>
          <b-nav-item :to="{ name: 'AppUTDiagram' }">Diagram</b-nav-item>
          <b-nav-item :to="{ name: 'AppUTExport' }">Export</b-nav-item>
          <b-nav-item :to="{ name: 'AppUTEditor' }">Editor</b-nav-item>
          <b-nav-item :to="{ name: 'Start' }"><b-icon-box-arrow-right aria-hidden="true"></b-icon-box-arrow-right> Exit</b-nav-item>
        </b-nav>
      </div>
    </b-sidebar>
  </div>
</div>
</template>

<script>
import ac from '@/libs/accesscontrol'
import { utParseData } from '@/libs/apps.js'
import { BIconBoxArrowRight, BIconHouseDoor, BIconThreeDotsVertical } from 'bootstrap-vue'

export default {
  components: {
    BIconBoxArrowRight,
    BIconHouseDoor,
    BIconThreeDotsVertical
  },
  computed: {
    ad: function () {
      return this.$store.state.appData
    },
    backgroundClass: function () {
      if (this.$route.params.appid === 'algo-trading') {
        return 'app-background-algo'
      }
      if (this.$route.params.appid === 'about') {
        return 'app-background-about'
      }
      return 'app-background-ut'
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    const app = this.apps.find(x => x.id === this.$route.params.appid)
    this.$stat.log({ page: 'UT app start', action: 'open UT app' })
    if (app.id === 'about') {
      this.permissions.ut = true
    } else {
      this.permissions.ut = ac.can(this.user.acgroups).readAny(app.resource).granted
    }
    this.load()
  },
  data () {
    return {
      apps: [{
        id: 'about',
        resource: 'appabout'
      }, {
        id: 'algo-trading',
        resource: 'appalgotrading'
      }, {
        id: 'ut',
        resource: 'apput'
      }],
      baseUrl: this.$config.BASE_URL,
      database: null,
      ready: false,
      permissions: {}
    }
  },
  methods: {
    load: async function () {
      try {
        const db = await await this.$Amplify.API.get('cosmos', `/app/${this.$route.params.appid}`)
        const { toml, dbJson } = utParseData(db.body)
        this.$store.commit('setAppData', dbJson)
        this.$store.commit('setAppDataToml', toml)
        this.ready = true
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-background-about {
  background-image: url('/img/digital.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.app-background-algo {
  background-image: url('/img/electronic.webp');
  background-repeat: no-repeat;
  background-size: cover;
}

.app-background-ut {
  background-image: url('/img/uk_background_image.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

/deep/ a, .nav-link {
  color: white;
}
/deep/ a:hover, .nav-link:hover {
  color: white;
}
</style>
